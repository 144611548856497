import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { RouterModule } from "@angular/router";

import { A11yModule } from "@angular/cdk/a11y";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PortalModule } from "@angular/cdk/portal";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";
import { SmepaymentinvoiceComponent } from "./sme/smepaymentinvoice/smepaymentinvoice.component";
import { SmecancelpaymentComponent } from "./sme/smecancelpayment/smecancelpayment.component";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./medical/dashboard/dashboard.component";
// import { DashboardComponent } from './dashboard/dashboard.component';
import { HomePaymentinvoiceComponent } from "./home/flexiquote/flexiinvoicepayment/paymentinvoice.component";


import { NgSelectModule } from "@ng-select/ng-select";

import { MaterialuiModule } from "./materialui/materialui.module";
import { DatePipe } from "@angular/common";

import { HttpClient,HttpClientModule } from "@angular/common/http";
import { AgmCoreModule } from "@agm/core";

import { AuthGuard } from "./_service/auth.guard";
//JWT MODULE
import { JwtModule } from "@auth0/angular-jwt";

import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { LandingAuthUserComponent } from "./layouts/landing-auth-user/landing-auth-user.component";
import { LoginUserComponent } from "./layouts/login-user/login-user.component";
//START:INCLUDE SERVICES
import { JwtService } from "./_service/jwt.service";
import { GlobalService } from "./_service/global.service";
import { UserauthService } from "./_service/userauth.service";
import { HomequoteService } from "./_service/homequote.service";
import { ThemeService } from 'ng2-charts';
import {EncrDecrService} from './_service/EncrDecrService';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MedicalMafFormComponent } from './medical/medical-maf-form/medical-maf-form.component';
import { ThankyouComponent } from './medical/memberlist/thankyou/thankyou.component';
import { PaymentinvoiceComponent } from './medical/memberlist/paymentinvoice/paymentinvoice.component';
import { MotorpaymentinvoiceComponent } from './agent_motor/agentmotorquote/motorpaymentinvoice/motorpaymentinvoice.component';
import { MedicalcancelpaymentComponent } from './medical/medicalcancelpayment/medicalcancelpayment.component';
// import { MotorThankyouComponent } from './agent_motor/agentmotorquote/thankyou/thankyou.component';
import { MotorcancelpaymentComponent } from './agent_motor/agentmotorquote/motorcancelpayment/motorcancelpayment.component';
import { TravelcancelpaymentComponent } from './travel/travelquote/travelcancelpayment/travelcancelpayment.component';
import { TravelPaymentinvoiceComponent } from "./travel/travelinvoicepayment/paymentinvoice.component";
import { ThankYouComponent } from "./travel/travelquote/thank-you/thank-you.component";
import { CommonThankYouComponent } from "./common-tank-you/thank-you.component";
import {
  MatPaginatorModule
} from "@angular/material";
import { ConfigService } from "./_service/config.service";
// import { ThankYouComponenthome } from "./home/homequote/thank-you/thank-you.component";
import { HomequoteModule } from "./home/homequote/homequote.module";
import { HolidayquoteModule } from "./home/holidayquote/holidayquote.module";
import {AgentmotorquoteModule} from "./agent_motor/agentmotorquote/agentmotorquote.module"
import { PetComponent } from './pet/pet.component';
import { MatAutocompleteModule } from "@angular/material";
import { ThankyouSMEComponent } from "./sme/thankyou/thankyou.component";
import { ThankyouSMEFixedComponent } from "./sme/sme-fixed/thankyou/thankyou.component";
import { ThankyouSMERiskComponent } from "./sme/sme-allrisk/thankyou/thankyou.component";
import { SmeModule } from "./sme/sme.module";
import { SmeRoutingModule } from "./sme/sme-routing.module";
import { MarineModule } from "./marine/marine.module";
import { NCDFormModelComponent } from "./agent_motor/motornongcc/ncdform-model/ncdform-model.component";
import { GeoLocationComponent } from "./sme/geo-location-modal/geo-location.component";
import { LoginComponent } from './login/login.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ResetpasswordComponent } from './login/resetpassword/resetpassword.component';
import { LogoutComponent } from './login/logout/logout.component';
import { AdminAccessibilityComponent } from "./login/admin-proxy-access/admin-proxy.component";
import { MailMafQuoteToolComponent } from "./medical/sme-medical-rating/mailmafquotetool/mailmafquotetool.component";

// import { GoogleMapsModule } from "@angular/google-maps";

// import { EexpedisComponent } from "./travel/standardtravel/eexpedis/eexpedis.component";

//END:INCLUDE SERVICES
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
  return localStorage.getItem("backend-token");
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    FormsModule,
    NgxMatSelectSearchModule,
    NgSelectModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MaterialuiModule,
    HttpClientModule,
    MatPaginatorModule,
    HomequoteModule,
    HolidayquoteModule,
    SmeModule,
    SmeRoutingModule,
    MarineModule,
    NgxCaptchaModule,

    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["localhost:4200"],
        blacklistedRoutes: ["http://localhost:4200/dashoard"],
      },
    }),

    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    AgentmotorquoteModule,
    MatAutocompleteModule
  ],
  exports: [
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    PortalModule,
    ScrollingModule,
    AgmCoreModule 
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LandingAuthUserComponent,LoginUserComponent,DashboardComponent, 
    MedicalMafFormComponent,ThankyouComponent,PaymentinvoiceComponent,
    MotorpaymentinvoiceComponent,MedicalcancelpaymentComponent,
    ThankYouComponent,HomePaymentinvoiceComponent,SmepaymentinvoiceComponent,SmecancelpaymentComponent,
    // ThankYouComponenthome,
    ThankyouSMEComponent,ThankyouSMEFixedComponent,ThankyouSMERiskComponent,
    MotorcancelpaymentComponent,TravelcancelpaymentComponent,
    TravelPaymentinvoiceComponent,
    CommonThankYouComponent,
    LoginComponent,
    ResetpasswordComponent,
    LogoutComponent,
    AdminAccessibilityComponent,
    MailMafQuoteToolComponent
    // NCDFormModelComponent
  
   
  ],
  // entryComponents: [NCDFormModelComponent],
  providers: [AuthGuard,GlobalService,JwtService,UserauthService,DatePipe,HomequoteService,EncrDecrService,ThemeService],
  bootstrap: [AppComponent],
  entryComponents: [GeoLocationComponent],
})
export class AppModule {}
