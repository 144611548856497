import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { catchError, tap, map } from 'rxjs/operators';
import {  of, throwError, observable } from 'rxjs';
import { GlobalService } from "../_service/global.service";
import { config } from './../config';
import {MatSnackBar} from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { NgModel } from '@angular/forms';
import { makeArray } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class SmequoteService {

  apiURL: string = config.apiUrl; 
  paymentAPIURL = config.paymentGatewayUrl;
  constructor(private httpClient: HttpClient,public _globalService: GlobalService, private _snackBar: MatSnackBar) {}

  public openSnackBar(message: string, action: string) {
     
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition:'top',
      horizontalPosition:'center',
      direction:'ltr',
      panelClass : ['snackbar']
    });
  }

  public handleError(error) {
   
  let   errorMessage :any = {};
      if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
       } else {
              // server-side error
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // console.error(error.status);
        if(error.status == 0){
              //window.alert("There is server error. Please try again later");
              Swal.fire("There is server error", "Please try again later", "error");
        }
        if(error.status == 500){
          Swal.fire("Please select valid file format.", "", "error");
        }
        

        return throwError(errorMessage);


    // if (error.status == 0) {
    //  errorMessage = {
    //     success: false,
    //     status: 0,
    //     data: "Sorry, there was a connection error occurred. Please try again."
    //   };
    // } else {
    //   errorMessage = error.json();
    // }
    // return Observable.throw(errorMessage);

    
}
//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getfloorcount(partnerID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetFloorsCount`,{partnerID:partnerID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
  //------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getUWNames(partnerID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetUWList`,{partnerID:partnerID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
  //------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getAnnualWages(partnerID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetAnnualWages`,{partnerID:partnerID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
  //------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getCoverPremises(partnerID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetCoverPremises`,{partnerID:partnerID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }

  public getIIRISProductcodes(partnerID):Observable<any> {

    return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetIIRISProductCodes`,{partnerID:partnerID},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));   
    }
//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getannualturnover(partnerID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetAnnualTurnover`,{partnerID:partnerID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getbusinessarea(partnerID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetBusinessArea`,{partnerID:partnerID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getClaimHistory(partnerID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetClaimHistroy`,{partnerID:partnerID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
  //------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getEmirates(partnerID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/getEmirates`,{partnerID:partnerID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
  //------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getemiratemall(partnerID,Id):Observable<any> {
// console.log(Id);
  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/getemiratesmall`,{partnerID:partnerID,emiratesId:Id},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getConstructionClass(partnerID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetConstructionClass`,{partnerID:partnerID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getbusinesspremise(partnerID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetBusinessPremise`,{partnerID:partnerID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getIndustrySegment(partnerID,prodCode):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetIndustrySegment`,{partnerID:partnerID,prodCode:prodCode},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getBussinessIndustry(partnerID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetBusinessSegmentByIndustry`,{partnerID:partnerID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getfreezone(partnerID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetFreeZones`,{partnerID:partnerID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getBusinesSegment(partnerID,industryId,prodCode):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetBusinessSegmentByIndustry`,{partnerID:partnerID,industryId:industryId,prodCode:prodCode},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public getQuote(QuoteParamArray):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/InsertUpdateQuotation`,{QuoteParamArray:QuoteParamArray},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }

//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
public saveQuotationDetails(quotedetail,coverdetails,EmployeeHeaderdetails):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/SaveSMEQuoteBenefitDetails`,{quotedetail:quotedetail,coverdetails:coverdetails,EmployeeHeaderdetails: EmployeeHeaderdetails},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }

  public saveQuotationDetailsfixed(quotedetail,coverdetails,plandetails):Observable<any> {

    return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/SaveSMEQuoteBenefitDetailsFixed`,{quotedetail:quotedetail,coverdetails:coverdetails,plandetails:plandetails},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));   
    }

    public saveQuotationDetailsallrisk(quotedetail,coverdetails):Observable<any> {

      return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/SaveSMEQuoteBenefitDetailsAllRisk`,{quotedetail:quotedetail,coverdetails:coverdetails},this._globalService.getHeader())
            .pipe(catchError(this.handleError), tap(res => {
              let  response=this._globalService.convertResponseInJson(res);
    
            }));   
      }    
public smeissuePolicy(savePolicy):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/InsertSMEPolicy`,{savePolicy:savePolicy},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }

  public insertPolicy(policyValuesArray,locationArray,source): Observable<any> {
    return this.httpClient.post(`${this.apiURL}HomeInsuranceWebservice/insertPolicy`,{policyValuesArray: policyValuesArray,locationValuesArray: locationArray,source: source,},{ ...Option, responseType: "text" })
      .pipe(catchError(this.handleError),tap((res) => {
          let res1 = JSON.parse(res);
          // let  response=this._globalService.convertResponseInJson(res1);
        })
      );
  }
  
public smesavePolicy(savePolicy):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/SaveSMEPolicyDetails`,{quotedetail:savePolicy},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
  // ------------------------------------------Print Quotation Pdf------------------------------------------------------------
public printsmepdf(QuoteArray,quotationNumber):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetSMESheildQuotationPDF`,{QuoteArray:QuoteArray,QuotationNumber:quotationNumber},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
  // ------------------------------------------Email Quotation Pdf------------------------------------------------------------
public emailSMEquote(QuoteArray,quotationNumber,email):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/sendSMESheildEmail`,{QuoteArray:QuoteArray,QuotationNumber:quotationNumber,email:email},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
public getProcessSMERenewal(source,prodCode,language,PrvPolNumber,status,policyType,claimCount,claimAmount,expiringPremium):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/ProcessSMERenewal`,{source:source,prodCode:prodCode,language:language,PrvPolNumber:PrvPolNumber,status:status,policyType:policyType,claimCount:claimCount,claimAmount:claimAmount,expiringPremium:expiringPremium},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
public getproduct(lobcode,sourec):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetProductsByPartner`,{lobcode:lobcode,source:sourec},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
public creditDebitNote(source,policy_no):Observable<any> {

  return this.httpClient.post(`${this.apiURL}Webservice/generateDebitCreditNote`,{source:source,policy_no:policy_no},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
public uploadDocuments(formData):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/UploadDocuments`,formData)
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }
public uploadDocumentsXls(formData):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/UploadEmployeeList`,formData)
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));   
  }

  // ------------------------------Policy Pdf--------------------------------------------------------------

  public getPolSchedulPDF(policy_no,source,quote_Num,product_code):Observable<any> {

    return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetPolicyDetailsPDF`,{source:source,policy_no:policy_no,quote_Num:quote_Num,product_code:product_code},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));   
    }
    // ------------------------------Policy Pdf--------------------------------------------------------------

  public GetTaxInvoice(policy_no,source,partner_id,quote_Num):Observable<any> {

    return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetTaxInvoice`,{source:source,policy_no:policy_no,partner_id:partner_id,quote_Num:quote_Num},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));   
    }

   public GetDebitTaxInvoice(policy_no,source,quote_Num):Observable<any> {

    return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetDebitTaxInvoice`,{source:source,policy_no:policy_no,quote_Num:quote_Num},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));   
    }  

    //------------------------------------------ FOR GET Workmen Certificate ---------------------------------------------------//
public getWorkmenCertificate(policy_no,source,quote_Num,product_code,locationid):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetWorkmenDetailsPDF`,{policy_no : policy_no,source:source,quote_Num:quote_Num,product_code:product_code,locationId : locationid},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}

//------------------------------------------ FOR GET Policy Certificate ---------------------------------------------------//
public getPolicyCertificate(policy_no,source,quote_Num,product_code,locationid):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetPolicyCertificatePDF`,{policy_no : policy_no,source:source,quote_Num:quote_Num,product_code:product_code,locationId : locationid},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}

public getPolicyCertificateBank(policy_no,source,quote_Num,product_code,covertype,locationid):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetPolicyCertificateBanknPDF`,{policy_no : policy_no,source:source,quote_Num:quote_Num,product_code:product_code,covertype: covertype,locationId : locationid},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}

public getFreeZoneCertificate(policy_no,source,quote_Num,product_code,covertype,locationid):Observable<any> {

  return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetFreeZoneCertificatePDF`,{policy_no : policy_no,source:source,quote_Num:quote_Num,product_code:product_code,covertype: covertype,locationId : locationid},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}
  // ------------------------------------------get Document List--------------------------------------------------------------

  public getDocumentList(source,lobcode,claimtype,claimrefnum):Observable<any> {

    return this.httpClient.post(`${this.apiURL}Webservice/getDocumentsList`,{source:source,lobcode:lobcode,claimtype:claimtype,claimrefnum:claimrefnum},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));   
    }
  // ------------------------------------------get Document List--------------------------------------------------------------

  public getQuotationDetails(policyType,quotationNumber,partner_id,source):Observable<any> {

    return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetQuotationDetails`,{policyType:policyType,quotationNumber:quotationNumber,partner_id:partner_id,source:source},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));   
    }
  // ------------------------------------------Get Wording  Document --------------------------------------------------------------

  public getWordingDocuments(lobcode,source,productCode):Observable<any> {

    return this.httpClient.post(`${this.apiURL}Webservice/GetPolicyWordingDocs`,{lobcode:lobcode,source:source,productCode:productCode},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));   
    }
  // ------------------------------------------GetReferal  --------------------------------------------------------------

  public getReferal(quotation_number,refReason,comment,event_type,refer_type,additional_doc_data,ref_aditional_condition,RefAssignedTo):Observable<any> {

    return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/quotationReferralFunctionality`,{quotation_number:quotation_number,refReason:refReason,comment:comment,event_type:event_type,refer_type:refer_type,additional_doc_data:additional_doc_data,ref_aditional_condition:ref_aditional_condition,ref_assigned_to:RefAssignedTo},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));   
    }
  // ------------------------------------------Check Partner Credit Limit --------------------------------------------------------------

  // async return response = await checkPartnerCreditLimit(lob_code,premium ){

  //   return this.httpClient.post(`${this.apiURL}PolicyController/checkPartnerCL`,{lob_code:lob_code,premium :premium },this._globalService.getHeader())
  //         .pipe(catchError(this.handleError), tap(res => {
  //           let  response=this._globalService.convertResponseInJson(res);
  
  //         }));   
  //   }

  async checkPartnerCreditLimit(lob_code,premium,agent_code, selected_branch){
    return await this.httpClient.post(`${this.apiURL}PolicyController/checkPartnerCL`,{lob_code:lob_code,premium :premium,agent_code:agent_code,selected_branch:selected_branch },this._globalService.getHeader()).toPromise();
  }

//Added new service method by Anju for credit limit check
  // ---------------------------------------Check partner Credit------------------------------------------------------
  public checkPartner_CreditLimit(lob_code,premium, agent_code, selected_branch): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiURL}CommercialInsuranceController/checkPartnerCL`,
        { lob_code:lob_code,premium :premium,agent_code:agent_code,selected_branch:selected_branch },
        this._globalService.getHeader()
      )
      .pipe(
        catchError(this.handleError),
        tap((res) => {
          let response = this._globalService.convertResponseInJson(res);
        })
      );
  }

  public GetRenewalNotice(policy_no:string,partnerId:string):Observable<any> {
    return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetRenewalNotice`,{source:"B2B",policy_no:policy_no,PartnerId:partnerId},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);  
          }));   
    }  
  public deleteEmployeeList(QuotationNumber,locationId):Observable<any> {
    return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/DeleteEmployeeList`,{QuotationNumber:QuotationNumber,LocationId : locationId},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
          }));   
    }

    async checkPartnerCreditDays(agent_code){
      return await this.httpClient.post(`${this.apiURL}PolicyController/checkPartnerCreditDays`,{agent_code:agent_code },this._globalService.getHeader()).toPromise();
    }
    public GetInitiatedPolicyDetails(quotationNumber,partnerId):Observable<any> {
      return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetInitiatedPolicyDetails`,{source:"B2B",partner_id:partnerId,quotationNumber:quotationNumber},this._globalService.getHeader())
            .pipe(catchError(this.handleError), tap(res => {
              let  response=this._globalService.convertResponseInJson(res);
            }));   
      }
   public UpdateInitiatedSMEPolicy(savePolicy):Observable<any> {
debugger;
        return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/UpdateInitiatedSMEPolicy`,{savePolicy:savePolicy},this._globalService.getHeader())
              .pipe(catchError(this.handleError), tap(res => {
                let  response=this._globalService.convertResponseInJson(res);
      
              }));   
        }
     public getPaymentOrderID(lob, quoteNo): Observable<any> {
          return this.httpClient
            .post(
              `${this.apiURL}PaymentWebservice/getPaymentOrderId`,
              { LOBCode: lob, QuoteNum: quoteNo },
              this._globalService.getHeader()
            )
            .pipe(
              catchError(this.handleError),
              tap((res) => {
                debugger;
                let response = this._globalService.convertResponseInJson(res);
              })
            );
        }
        public verifyPayment(orderNumber): Observable<any> {
          return this.httpClient
            .get(this.paymentAPIURL + 'pay-status/' + orderNumber)
            .pipe(
              catchError(this.handleError),
              tap((res) => {
                let response = this._globalService.convertResponseInJson(res);
              })
            );
        }
        // SMEReceipt Jan2024 Changes Start
        public GetReceipt(policy_no,source,quote_Num):Observable<any> {

          return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/GetReceipt`,{source:source,policy_no:policy_no,quote_Num:quote_Num},this._globalService.getHeader())
                .pipe(catchError(this.handleError), tap(res => {
                  let  response=this._globalService.convertResponseInJson(res);
        
                }));   
          }
          // SMEReceipt Jan2024 Changes END

      public checkDirectSalesAgent(partnerId):Observable<any> {

        return this.httpClient.post(`${this.apiURL}Webservice/checkDirectSalesAgent`,{partnerId:partnerId},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
          }));   
    }

    //-------------------------------------- FOR GET QUOTATION HISTORY -----------------------------------------------------//
    public getQuotationHistory(quoteNumber, lob): Observable<any> {
      return this.httpClient
        .post(
          `${this.apiURL}CommercialInsuranceController/getQuotationHistory`,
          { quotation_number: quoteNumber, lob: lob },
          this._globalService.getHeader()
        )
        .pipe(
          catchError(this.handleError),
          tap((res) => {
            let response = this._globalService.convertResponseInJson(res);
          })
        );
    }

    //-------------------------Get All ENitity name ----------------------------------
    getAllBusinessEntityName()
    {
      return  this.httpClient
        .get(`${this.apiURL}CommercialInsuranceController/getAllBusinessEnitityName`)
        .pipe(
          catchError(this.handleError),
          tap((res) => {
            let response = this._globalService.convertResponseInJson(res);
          })
        ).toPromise();
    }

    // public searchRecords(searchstring): Observable<any>
    // {
    //   debugger;
    //   console.log(searchstring)
    //   return  this.httpClient
    //   .get(`${this.apiURL}CommercialInsuranceController/getAllBusinessEnitityName`,{
    //     params: { search: searchstring } // Pass the search string as a query parameter
    //   })
    //   .pipe(
    //     catchError(this.handleError),
    //     tap((res) => {
    //       let response = this._globalService.convertResponseInJson(res);
    //     })
    //   );
    

    //}
    public searchRecords(searchstring: string): Observable<any> {
      debugger;
      return this.httpClient
        .get(`${this.apiURL}CommercialInsuranceController/getAllBusinessEnitityName`, {
          params: { search: searchstring } // Pass the search string as a query parameter
        })
        .pipe(
          map((res: any) => {
            // Assuming res has a property BusinessNameList that contains the results.
            return res.BusinessNameList;  // Return an empty array if no BusinessNameList
          }),
          catchError((error) => {
            console.error('Error occurred during search:', error);
            return of([]); // Return an empty array in case of an error
          })
        );
    }

    public saveAdditionalConditions(conditionsArray,quoteNum):Observable<any> {

      return this.httpClient.post(`${this.apiURL}CommercialInsuranceController/SaveAdditionalConditions`,{conditions:conditionsArray,quote_num :quoteNum },this._globalService.getHeader())
            .pipe(catchError(this.handleError), tap(res => {
              let  response=this._globalService.convertResponseInJson(res);
    
            }));   
    }
    

}
