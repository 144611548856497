import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SmequoteService } from 'app/_service/smequote.service';

@Component({
  selector: 'app-smequote',
  templateUrl: './smequote.component.html',
  styleUrls: ['./smequote.component.scss']
})
export class SmequoteComponent implements OnInit {
  product: any;

  constructor(public smequoteService: SmequoteService,public _route: Router,) { }

  ngOnInit() {
    this.getProductBypartner();
  }


  getProductBypartner(){

    this.smequoteService.getproduct('SM','B2B').subscribe(res=>{
      this.product = res.productByPartnerData;
      // console.log(this.product);
    })
  }
  getQuote(product_code,productId){
    console.log(product_code);
    // localStorage.setItem("ProductCode" ,product_code)
    if(product_code == 'SMEBI'){
      this._route.navigate(["sme/issuepolicy"]);
    }
    else if (product_code == 'DGSME') {
      this._route.navigate(["sme/smefixed"]);
    }
    else if(product_code ==   'SMEPAR')
    {
      this._route.navigate(['sme/issuepolicy/SMEPAR'])
    }
    else if(product_code ==   'SMEPL')
    {
      this._route.navigate(['sme/issuepolicy/SMEPL'])
    }
    else if(product_code ==   'SMEWC')
      {
        this._route.navigate(['sme/issuepolicy/SMEWC'])
      }
    else {
      this._route.navigate(["sme/smeallrisk"]);
    }
  }
  

}
